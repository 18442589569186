// terrassa template

$brand-primary: #da121a; // red
$brand-secondary: #fcdd09; // yellow

$primary: $brand-primary;
$secondary: lighten($brand-secondary, 10%);

$headings-color: darken($brand-primary, 15%);

$link-color: $brand-primary;
$link-color-dark: lighten($brand-primary, 10%);

$navbar-light-color: $primary;
$navbar-light-hover-color:          $headings-color;
$navbar-light-active-color:         $headings-color;
$navbar-light-disabled-color:       desaturate($primary, 100%);
$navbar-light-toggler-border:       $headings-color;

@import "base";
// @import "clock";
@import "social-icons";

#topnav {
	background-color: var(--#{$prefix}secondary);
}

#jumbotron {
	@include gradient-radial($body-bg, lighten($brand-secondary, 33%));

	&[data-background] {
		color: $white;
		background: $gray-800 none;

		p, h1, h2, h3, h4 {
			text-shadow: 1px 1px 4px $black;
		}

		a:not([class]) {
			color: $secondary;
		}

		h1, h2, h3, h4 {
			color: $white;
		}

		.input-group {
			border-radius: $border-radius-lg;
			box-shadow: 1px 1px 4px $black;
		}
	}
}

footer {
	background-color: lighten($brand-secondary, 33%);
	color: darken($brand-primary, 15%);
}

@include color-mode(dark) {
	#jumbotron {
		@include gradient-radial($body-bg-dark, $body-secondary-bg-dark);
	}

	footer {
		background-color: $body-secondary-bg-dark;
		color: $gray-500;

		a, .nav-link {
			color: $gray-200;

			&:hover,
			&:focus {
				color: $white;
			}

		}
	}
}
